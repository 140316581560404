import actions from '../Dashboard/actions';
import {produce} from 'immer'
import { capitalizeFirstLetter } from '../../config/helpers';

const initialState = {
    dashboardLoaders: {
      getDetails: false,
      getOrders: false,
      getQuotes: false,
      getJobs: false,
      getAddresses: false,
      getCredits: false,
      getOrder: false,
    },
    emailLoaders: {
      sendEmail: false,
    },
    quotesLoaders: {
      getQuotes: false,
      sendEmail: false,
      getQuoteCharges: false,
      getQuoteDetails: false,
      getQuoteItems: false,
      getQuoteManagement: false,
    },
    ordersLoaders: {
      getOrders: false,
      getOrder: false,
      getOrderCharges: false,
      getOrderDetails: false,
      getOrderItems: false,
      getOrderManagement: false,
      getOrders: false,
    },
    addressLoaders: {
      getAddresses: false,
      copyAddress: false,
    },
    schedulePostcodeLoaders: {
      getDeliveries: false,
      getCollections: false,
    },
    preferencesLoaders: {
      getQuoteTemplates: false,
      getOrderTemplates: false,
    },
    dashboardData: {
      keys: {
        orders: [],
        quotes: [],
        credits: [],
        jobs: [],
        addresses: [],
      },
      quote: [],
      order: [],
      credit_note: [],
      status: null,
      message: false
    },
    quotes: [],
    orders: [],
    // convertQuote: {
    //   quote_id: null,
    //   state: false,
    //   error: false,
    //   success: false,
    //   new_quote_id: null,
    // },
    mobileDrawer: {
      open: false,
    },
    auditTrail: [],
    snapshots: [],
    quoteStates: { 
      // OriginalStates
      all_in_pricing: false,
      mode: 'view',
      authorisationDialog: false,
      unavailableProductsDialog: false,
      lostReasonDialog: false,
      stockDepotDialog: false,
      finishedInit: false,
      quickEditMode: false,
      runInitialStockChecks: false,
      calculatedDiscounts: false,
      checklistDialog: {
        open: false,
        type: ''
      },
      deliveryDatePassed: false,
      editable: false,
      auditTrail: false,
      cloneDialog: false,
      createVariant: false,
      manageVariants: false,
      extensionDialog: false,
      navigateDialog: {
        open: false,
        href: ''
      },
      mandatoryService: false,
      readyToUpdateQuote: false,
      addingNewProduct: false,
      removingProduct: false,
      canMakePayment: null,
      invalidDate: null,
      showBacs: false,
      showPaymentForm: false,
      processingUpdate: false,
      quoteLoading: false,
      checkingStock: false,
      readyForStockCheck: false,
      check_depot_id: null,
      postcode_depot_id: null,
      updateQuote: false,
      quoteProducts: [],
      retrievedQuoteCosts: [],
      calculatingNewCosts: false,
      recalculatingQuote: false,
      products: [],
      quoteLoader: false,
      chooseCustomerMiddleware: false,
      stockLog: {
        entries: [],
        complete: "",
      },
      currentSurcharge: {
        delivery: false,
        collection: false,
        install: false,
        derig: false,
      },
      swapProduct: {
        state: false,
        index: null,
        product: null,
        temp_product: null,
      },
      currentSurchargeID: {
        delivery: false,
        collection: false,
      },

      transportToRemove: {
        transportType: null,
        optionType: null,
      },

      fetchTransportPricing: false,
      recalculateQuote: false,

      // Pricing
      updateHNSPricing: false,
      toggleDamageWaiver: false,
      what3wordsMap: {
        open: false,
        type: ''
      },

      //Extras
      deliverySameAsBilling: false,
      collectionSameAsDelivery: false,
      showNewCustomerDialog: false,
      showQuoteStatusModal: false,
      isBillingAddressFilled: false,
      Extension: false,
      no_damage_waiver: false,
      LockCarriage: false,
      LockPrices: false,
      emailValid: false,
      carriageRemoved: false
    },
    quote: [],
    quote_payment: {
      make_payment: false,
      create_order: false,
      create_carriage: false,
      bacs: false,
      loading: false,
      complete: false,
      error: false,
      message: "",
      failed_payment: false,
    },
    order_payment: {
      make_payment: false,
      update_order: false,
      loading: false,
      complete: false,
      error: false,
      message: "",
      failed_payment: false,
    },
    updatedQuote: [],
    checkList: {
      sameAsDelivery: true,
      deliveryChecklistCompleted: false,
      collectionChecklistCompleted: false,
    },
    successfulQuoteUpdate: false,
    order: [],
    orderStates: {
      editMode: false,
      paymentTermsDialog: {
        opem: false
      }
    },
    costs: [],
    vehicleSizes: {
        data: []
    },

    //dash/addresses
    addresses: {
      data: {
        address_list: []
      }
    },
    schedule: {
      deliveries: [],
      collections: []
    },

    preferences: [],
    system_preferences: []

}

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SETUP_DASHBOARD_DATA:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, getTableData: true, },
    }
    case actions.SETUP_DASHBOARD_DATA_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          ...action.payload,
        },
        dashboardLoaders: { ...state.dashboardLoaders, getTableData: false },
    }
    case actions.SETUP_DASHBOARD_DATA_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          ...action.payload
        },
        dashboardLoaders: { ...state.dashboardLoaders, getTableData: false },
    }

    case actions.SETUP_DASHBOARD:
      return {
        ...state, 
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          [`get${capitalizeFirstLetter(action.payload.type)}`]: true,
        },
    }

    case actions.SETUP_DASHBOARD_DETAILS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          agent: [],
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getDetails: true,
        },
    }
    case actions.SETUP_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          agent: {
            ...action.payload.data.data,
            status: action.payload.data.status,
            message: action.payload.data.message,
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getDetails: false,
        },
    }
    
    case actions.SETUP_DASHBOARD_DETAILS_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          agent: action.payload
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getDetails: false,
        },
    }

    case actions.SET_QUOTE_BRAND_DATA:
      return {
        ...state, 
        quote: {
          ...state.quote,
          brand_data: []
        },
        quotesLoaders: {
          ...state.quotesLoaders, 
          getBrandData: true,
        },
    }
    case actions.SET_QUOTE_BRAND_DATA_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          brand_data: action.payload.data
        },
        quotesLoaders: {
          ...state.quotesLoaders, 
          getBrandData: false,
        },
    }
    
    case actions.SET_QUOTE_BRAND_DATA_FAILURE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          brand_data: action.payload.data
        },
        quotesLoaders: {
          ...state.quotesLoaders, 
          getBrandData: true,
        },
    }

    case actions.SET_ORDER_BRAND_DATA:
      return {
        ...state, 
        order: {
          ...state.order,
          brand_data: []
        },
        ordersLoaders: {
          ...state.dashboardLoaders, 
          getBrandData: true,
        },
    }
    case actions.SET_ORDER_BRAND_DATA_SUCCESS:
      return {
        ...state, 
        order: {
          ...state.order,
          brand_data: action.payload.data
        },
        ordersLoaders: {
          ...state.dashboardLoaders, 
          getBrandData: false,
        },
    }
    
    case actions.SET_ORDER_BRAND_DATA_FAILURE:
      return {
        ...state, 
        order: {
          ...state.order,
          brand_data: action.payload.data
        },
        ordersLoaders: {
          ...state.dashboardLoaders, 
          getBrandData: true,
        },
    }

    case actions.SETUP_DASHBOARD_QUOTES:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: [],
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getQuotes: true,
        },
    }
    case actions.SETUP_DASHBOARD_QUOTES_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload.data
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getQuotes: false,
        },
    }
    
    case actions.SETUP_DASHBOARD_QUOTES_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getQuotes: false,
        },
    }
    case actions.SETUP_DASHBOARD_ORDERS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: [],
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getOrders: true,
        },
    }
    case actions.SETUP_DASHBOARD_ORDERS_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload.data
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getOrders: false,
        },
    }
    
    case actions.SETUP_DASHBOARD_ORDERS_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getOrders: false,
        },
    }
    case actions.SETUP_DASHBOARD_ADDRESSES:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: [],
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getAddresses: true,
        },
    }
    case actions.SETUP_DASHBOARD_ADDRESSES_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload.data
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getAddresses: false,
        },
    }
    
    case actions.SETUP_DASHBOARD_ADDRESSES_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getAddresses: false,
        },
    }
    case actions.SETUP_DASHBOARD_JOBS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: [],
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getJobs: true,
        },
    }
    case actions.SETUP_DASHBOARD_JOBS_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload.data
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getJobs: false,
        },
    }
    
    case actions.SETUP_DASHBOARD_JOBS_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getJobs: false,
        },
    }

    case actions.SETUP_DASHBOARD_CREDITS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: [],
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getCredits: true,
        },
    }
    case actions.SETUP_DASHBOARD_CREDITS_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload.data
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getCredits: false,
        },
    }
    
    case actions.SETUP_DASHBOARD_CREDITS_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          keys: {
            ...state.dashboardData.keys,
            [action.payload.type]: action.payload
          }
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          getCredits: false,
        },
    }
    case actions.UPDATE_CUSTOMER_COMM_LOG:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          agent: {
            ...state.dashboardData.agent
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          updateCommLog: true,
        },
    }
    case actions.UPDATE_CUSTOMER_COMM_LOG_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          agent: {
            ...state.dashboardData.agent,
            CommLog: action.payload.CommLog
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          updateCommLog: false,
        },
    }
    
    case actions.UPDATE_CUSTOMER_COMM_LOG_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          agent: {
            ...state.dashboardData.agent,
            ...action.payload
          },
        },
        dashboardLoaders: {
          ...state.dashboardLoaders, 
          updateCommLog: false,
        },
    }
    case actions.GET_LIST_OF_PREFERENCES:
      return {
        ...state, 
        system_preferences: [],

        preferencesLoaders: {
          ...state.preferencesLoaders, 
          getSystemPreferences: true,
        },
    }
    case actions.GET_LIST_OF_PREFERENCES_SUCCESS:
      return {
        ...state, 
        system_preferences: {
          ...action.payload,
          system_preferences_ready: true
        },

        preferencesLoaders: {
          ...state.preferencesLoaders, 
          getSystemPreferences: false,
        },
    }
    
    case actions.GET_LIST_OF_PREFERENCES_FAILURE:
      return {
        ...state, 
        system_preferences: [],
        preferencesLoaders: {
          ...state.preferencesLoaders, 
          getSystemPreferences: false,
        },
    }

    case actions.DASHBOARD_GET_QUOTE_BY_ID:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, getQuote: true, },
    }
    case actions.DASHBOARD_GET_QUOTE_BY_ID_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          quote: action.payload
        },
        dashboardLoaders: { ...state.dashboardLoaders, getQuote: false },
    }
    case actions.DASHBOARD_GET_QUOTE_BY_ID_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          quote: action.payload
        },
        dashboardLoaders: { ...state.dashboardLoaders, getQuote: false },
    }

    case actions.DASHBOARD_GET_ORDER_BY_ID:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, getOrder: true, },
    }
    case actions.DASHBOARD_GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          order: action.payload
        },
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }
    case actions.DASHBOARD_GET_ORDER_BY_ID_FAILURE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          order: action.payload
        },
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }

    case actions.FETCH_LOST_QUOTE_REASONS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          lost_quote_reasons: []
        },
        quotesLoaders: {...state.quotesLoaders, getLostQuoteReasons: true, },
    }
    case actions.FETCH_LOST_QUOTE_REASONS_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          lost_quote_reasons: action.payload,
        },
        quotesLoaders: {...state.quotesLoaders, getLostQuoteReasons: false, },
    }
    case actions.FETCH_LOST_QUOTE_REASONS_FAILURE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          lost_quote_reasons: action.payload,
        },
        quotesLoaders: {...state.quotesLoaders, getLostQuoteReasons: false, },
    }
    

    case actions.GET_SINGLE_QUOTE_DETAILS:
      return {
        ...state, 
        quote: [],
        quote_payment: initialState.quote_payment,
        updatedQuote: [],
        dashboardLoaders: {...state.dashboardLoaders, getOrder: true, },
    }
    case actions.GET_SINGLE_QUOTE_DETAILS_SUCCESS:
      return {
        ...state, 
        quote: action.payload,
        updatedQuote: [],
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }
    case actions.GET_SINGLE_QUOTE_DETAILS_FAILURE:
      return {
        ...state, 
        quote: action.payload,
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }

    case actions.GET_SINGLE_QUOTE_SECTION_DETAILS:
      return {
        ...state, 
        quotesLoaders: {...state.quotesLoaders, getQuoteDetails: true, },
    }
    case actions.GET_SINGLE_QUOTE_SECTION_DETAILS_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          keys: {
            ...state.quote.keys,
            ...action.payload.keys
          },
          ...action.payload.data,
          AgentData: action.payload.AgentData,
          results: {
            ...state.quote.results,
            details: action.payload.result
          },
        },
        quoteStates: {
          ...state.quoteStates,
          readyForStockCheck: true,
        },
        quotesLoaders: {...state.quotesLoaders, getQuoteDetails: false, },
    }
    case actions.GET_SINGLE_QUOTE_SECTION_DETAILS_FAILURE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          details: action.payload,
          results: {
            ...state.quote.results,
            details: action.payload.result
          },
        },
        quotesLoaders: {...state.quotesLoaders, getQuoteDetails: false, },
    }

    case actions.GET_SINGLE_QUOTE_SECTION_ITEMS:
      return {
        ...state, 
        quotesLoaders: {...state.quotesLoaders, getQuoteItems: true, },
    }
    case actions.GET_SINGLE_QUOTE_SECTION_ITEMS_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          keys: {
            ...state.quote.keys,
            ...action.payload.keys
          },

          brand_data: action.payload.brand_data,
          brand_collection: action.payload.brand_collection,
          depots: action.payload.depots,
          results: {
            ...state.quote.results,
            items: action.payload.result
          },
        },
        quoteStates: {
          ...state.quoteStates,
          readyForStockCheck: true,
        },
        quotesLoaders: {...state.quotesLoaders, getQuoteItems: false, },
    }
    case actions.GET_SINGLE_QUOTE_SECTION_ITEMS_FAILURE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          order_items: action.payload,
          results: {
            ...state.quote.results,
            items: action.payload.result
          },
        },
        quotesLoaders: {...state.quotesLoaders, getQuoteItems: false, },
    }

    case actions.GET_SINGLE_QUOTE_SECTION_CHARGES:
      return {
        ...state, 
        quotesLoaders: {...state.quotesLoaders, getQuoteCharges: true, },
    }
    case actions.GET_SINGLE_QUOTE_SECTION_CHARGES_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          keys: {
            ...state.quote.keys,
            ...action.payload.keys
          },
          results: {
            ...state.quote.results,
            charges: action.payload.result
          },
        },
        quoteStates: {
          ...state.quoteStates,
          readyForStockCheck: true,
        },
        quotesLoaders: {...state.quotesLoaders, getQuoteCharges: false, },
    }
    case actions.GET_SINGLE_QUOTE_SECTION_CHARGES_FAILURE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          charges: action.payload,
          results: {
            ...state.quote.results,
            charges: action.payload.result
          },
        },
        quotesLoaders: {...state.quotesLoaders, getQuoteCharges: false, },
    }

    case actions.GET_SINGLE_QUOTE_SECTION_MANAGEMENT:
      return {
        ...state, 
        quotesLoaders: {...state.quotesLoaders, getQuoteManagement: true, },
    }
    case actions.GET_SINGLE_QUOTE_SECTION_MANAGEMENT_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          keys: {
            ...state.quote.keys,
            ...action.payload.keys
          },
          ...action.payload.data,
          results: {
            ...state.quote.results,
            management: action.payload.result
          },
        },
        quotesLoaders: {...state.quotesLoaders, getQuoteManagement: false, },
    }
    case actions.GET_SINGLE_QUOTE_SECTION_MANAGEMENT_FAILURE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          results: {
            ...state.quote.results,
            management: action.payload.result
          },
        },
        quotesLoaders: {...state.quotesLoaders, getQuoteManagement: false, },
    }

    case actions.UPDATE_QUOTE_DETAILS:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, updatingQuote: true, },
    }
    case actions.UPDATE_QUOTE_DETAILS_SUCCESS:
      return {
        ...state, 
        successfulQuoteUpdate: true,
        dashboardLoaders: {...state.dashboardLoaders, updatingQuote: false, },
    }
    case actions.UPDATE_QUOTE_DETAILS_FAILURE:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, updatingQuote: false, },
    }

    case actions.UPDATE_QUOTE_CARRIAGE:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, updatingQuote: true, },
    }
    case actions.UPDATE_QUOTE_CARRIAGE_SUCCESS:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, updatingQuote: false, },
    }
    case actions.UPDATE_QUOTE_CARRIAGE_FAILURE:
      return {
      ...state, 
      dashboardLoaders: {...state.dashboardLoaders, updatingQuote: false, },
    }
    
    case actions.QUOTE_GET_EMAIL_LOG:
      return {
        ...state, 
        quotesLoaders: {...state.quotesLoaders, fetchingEmailLogs: true, },
    }
    case actions.QUOTE_GET_EMAIL_LOG_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          keys: {
            ...state.quote.keys,
            email_logs: action.payload.data
          }
        },
        quotesLoaders: {...state.quotesLoaders, fetchingEmailLogs: false, },
    }
    case actions.QUOTE_GET_EMAIL_LOG_FAILURE:
      return {
      ...state, 
      quote: {
        ...state.quote,
        keys: {
          ...state.quote.keys,
          email_logs: action.payload.data
        }
      },
      quotesLoaders: {...state.quotesLoaders, fetchingEmailLogs: false, },
    }


    // ORDER

    case actions.GET_SINGLE_ORDER_DETAILS:
      return {
        ...state, 
        order: [],
        quote_payment: initialState.quote_payment,
        updatedQuote: [],
        dashboardLoaders: {...state.dashboardLoaders, getOrder: true, },
    }
    case actions.GET_SINGLE_ORDER_DETAILS_SUCCESS:
      return {
        ...state, 
        order: action.payload,
        updatedQuote: [],
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }
    case actions.GET_SINGLE_ORDER_DETAILS_FAILURE:
      return {
        ...state, 
        order: action.payload,
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }

    case actions.GET_SINGLE_ORDER_SECTION_DETAILS:
      return {
        ...state, 
        ordersLoaders: {...state.ordersLoaders, getOrderDetails: true, },
    }
    case actions.GET_SINGLE_ORDER_SECTION_DETAILS_SUCCESS:
      return {
        ...state, 
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            ...action.payload.keys
          },
          ...action.payload.data,
          AgentData: action.payload.AgentData,
          results: {
            ...state.order.results,
            details: action.payload.result
          },
        },
        ordersLoaders: {...state.ordersLoaders, getOrderDetails: false, },
    }
    case actions.GET_SINGLE_ORDER_SECTION_DETAILS_FAILURE:
      return {
        ...state, 
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            details: action.payload,
          },
          results: {
            ...state.order.results,
            details: action.payload.result
          },
        },
        ordersLoaders: {...state.ordersLoaders, getOrderDetails: false, },
    }

    case actions.GET_SINGLE_ORDER_SECTION_ITEMS:
      return {
        ...state, 
        ordersLoaders: {...state.ordersLoaders, getOrderItems: true, },
    }
    case actions.GET_SINGLE_ORDER_SECTION_ITEMS_SUCCESS:
      return {
        ...state, 
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            ...action.payload.keys
          },
          brand_data: action.payload.brand_data,
          brand_collection: action.payload.brand_collection,
          depots: action.payload.depots,
          results: {
            ...state.order.results,
            items: action.payload.result
          },
        },
        ordersLoaders: {...state.ordersLoaders, getOrderItems: false, },
    }
    case actions.GET_SINGLE_ORDER_SECTION_ITEMS_FAILURE:
      return {
        ...state, 
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            order_items: action.payload,
          },
          results: {
            ...state.order.results,
            items: action.payload.result
          },
        },
        ordersLoaders: {...state.ordersLoaders, getOrderItems: false, },
    }

    case actions.GET_SINGLE_ORDER_SECTION_CHARGES:
      return {
        ...state, 
        ordersLoaders: {...state.ordersLoaders, getOrderCharges: true, },
    }
    case actions.GET_SINGLE_ORDER_SECTION_CHARGES_SUCCESS:
      return {
        ...state, 
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            ...action.payload.keys
          },
          results: {
            ...state.order.results,
            charges: action.payload.result
          },
        },
        ordersLoaders: {...state.ordersLoaders, getOrderCharges: false, },
    }
    case actions.GET_SINGLE_ORDER_SECTION_CHARGES_FAILURE:
      return {
        ...state, 
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            charges: action.payload,
          },
          results: {
            ...state.order.results,
            charges: action.payload.result
          },
        },
        ordersLoaders: {...state.ordersLoaders, getOrderCharges: false, },
    }

    case actions.GET_SINGLE_ORDER_SECTION_MANAGEMENT:
      return {
        ...state, 
        ordersLoaders: {...state.ordersLoaders, getOrderManagement: true, },
    }
    case actions.GET_SINGLE_ORDER_SECTION_MANAGEMENT_SUCCESS:
      return {
        ...state, 
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            ...action.payload.keys
          },
          ...action.payload.data,
          results: {
            ...state.order.results,
            management: action.payload.result
          },
        },
        ordersLoaders: {...state.ordersLoaders, getOrderManagement: false, },
    }
    case actions.GET_SINGLE_ORDER_SECTION_MANAGEMENT_FAILURE:
      return {
        ...state, 
        order: {
          ...state.order,
          results: {
            ...state.order.results,
            management: action.payload.result
          },
        },
        ordersLoaders: {...state.ordersLoaders, getOrderManagement: false, },
    }



    case actions.CREATE_NEW_QUOTE:
      return {
        ...state, 
        quote: [],
        quote_payment: initialState.quote_payment,
        updatedQuote: [],
        dashboardLoaders: {...state.dashboardLoaders, getOrder: true, },
    }
    case actions.CREATE_NEW_QUOTE_SUCCESS:
      return {
        ...state, 
        quote: action.payload,
        updatedQuote: action.payload.keys,
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }
    case actions.CREATE_NEW_QUOTE_FAILURE:
      return {
        ...state, 
        quote: action.payload,
        updatedQuote: [],
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }

    case actions.CREATE_BLANK_CUSTOMER_QUOTE:
      return {
        ...state, 
        quote: [],
        quote_payment: initialState.quote_payment,
        updatedQuote: [],
        dashboardLoaders: {...state.dashboardLoaders, getOrder: true, },
    }
    case actions.CREATE_BLANK_CUSTOMER_QUOTE_SUCCESS:
      return {
        ...state, 
        quote: action.payload,
        updatedQuote: action.payload.keys,
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }
    case actions.CREATE_BLANK_CUSTOMER_QUOTE_FAILURE:
      return {
        ...state, 
        quote: action.payload,
        updatedQuote: [],
        dashboardLoaders: { ...state.dashboardLoaders, getOrder: false },
    }



    case actions.DASHBOARD_UPDATE_QUOTE:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, updatingQuote: true, },
    }
    case actions.DASHBOARD_UPDATE_QUOTE_SUCCESS:
      return {
        ...state, 
        updateQuote: {
          ...action.payload
        },
        successfulQuoteUpdate: true,
        dashboardLoaders: { ...state.dashboardLoaders, updatingQuote: false },
    }
    case actions.DASHBOARD_UPDATE_QUOTE_FAILURE:
      return {
        ...state, 
        updateQuote: [],
        successfulQuoteUpdate: false,
        dashboardLoaders: { ...state.dashboardLoaders, updatingQuote: false },
    }

    case actions.DASHBOARD_GET_VEHICLE_SIZES:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, vehicleSizes: true, },
    }
    case actions.DASHBOARD_GET_VEHICLE_SIZES_SUCCESS:
      return {
        ...state, 
        vehicleSizes: action.payload,
        dashboardLoaders: { ...state.dashboardLoaders, vehicleSizes: false },
    }
    case actions.DASHBOARD_GET_VEHICLE_SIZES_FAILURE:
      return {
        ...state, 
        vehicleSizes: action.payload,
        dashboardLoaders: { ...state.dashboardLoaders, vehicleSizes: false },
    }

    case actions.DASHBOARD_GET_AUDIT_TRAIL:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, auditTrailLoading: true, },
    }
    case actions.DASHBOARD_GET_AUDIT_TRAIL_SUCCESS:
      return {
        ...state, 
        auditTrail: {...action.payload},
        dashboardLoaders: { ...state.dashboardLoaders, auditTrailLoading: false },
    }
    case actions.DASHBOARD_GET_AUDIT_TRAIL_FAILURE:
      return {
        ...state, 
        auditTrail: {...action.payload},
        dashboardLoaders: { ...state.dashboardLoaders, auditTrailLoading: false },
    }

    case actions.DASHBOARD_BUILD_SNAPSHOTS:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, snapshotsLoading: true, },
    }
    case actions.DASHBOARD_BUILD_SNAPSHOTS_SUCCESS:
      return {
        ...state, 
        snapshots: {...action.payload},
        dashboardLoaders: { ...state.dashboardLoaders, snapshotsLoading: false },
    }
    case actions.DASHBOARD_BUILD_SNAPSHOTS_FAILURE:
      return {
        ...state, 
        snapshots: {...action.payload},
        dashboardLoaders: { ...state.dashboardLoaders, snapshotsLoading: false },
    }

    case actions.DASHBOARD_QUOTE_HANDLE_PAYMENT:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, makingQuotePayment: true, },
    }
    case actions.DASHBOARD_QUOTE_HANDLE_PAYMENT_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          payment: action.payload,
        },
        dashboardLoaders: { ...state.dashboardLoaders, makingQuotePayment: false },
    }
    case actions.DASHBOARD_QUOTE_HANDLE_PAYMENT_FAILURE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          payment: action.payload,
        },
        dashboardLoaders: { ...state.dashboardLoaders, makingQuotePayment: false },
    }

    case actions.DASHBOARD_ORDER_HANDLE_PAYMENT:
      return {
        ...state, 
        dashboardLoaders: {...state.dashboardLoaders, makingOrderPayment: true, },
    }
    case actions.DASHBOARD_ORDER_HANDLE_PAYMENT_SUCCESS:
      return {
        ...state, 
        order: {
          ...state.order,
          payment: action.payload,
        },
        dashboardLoaders: { ...state.dashboardLoaders, makingOrderPayment: false },
    }
    case actions.DASHBOARD_ORDER_HANDLE_PAYMENT_FAILURE:
      return {
        ...state, 
        order: {
          ...state.order,
          payment: action.payload,
        },
        dashboardLoaders: { ...state.dashboardLoaders, makingOrderPayment: false },
    }

    case actions.DASHBOARD_QUOTE_HANDLE_BACS:
      return {
        ...state, 
        quote_payment: {
          ...state.quote_payment,
          loading: true,
          bacs: true
        }
    }
    case actions.DASHBOARD_QUOTE_HANDLE_BACS_SUCCESS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          payment: action.payload
        },
        quote_payment: {
          ...state.quote_payment,
          loading: false,
          bacs: true,
          complete: true
        }
    }
    case actions.DASHBOARD_QUOTE_HANDLE_BACS_FAILURE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          payment: action.payload
        },
        quote_payment: {
          ...state.quote_payment,
          loading: false,
          bacs: true,
          error: true
        }
    }
    
    case actions.QUOTE_ORDER_SEND_EMAIL:
      return {
        ...state, 
        emailLoaders: {...state.emailLoaders, sendEmail: true, },
    }
    case actions.QUOTE_ORDER_SEND_EMAIL_SUCCESS:
      return {
        ...state, 
        [action.payload.type]: {
          ...state[action.payload.type],
          sendEmail: {
            status: action.payload.status,
            message: action.payload.message
          },
        },
        emailLoaders: { ...state.emailLoaders, sendEmail: false },
    }
    case actions.QUOTE_ORDER_SEND_EMAIL_FAILURE:
      return {
        ...state, 
        [action.payload.type]: {
          ...state[action.payload.type],
          sendEmail: {
            status: action.payload.status,
            message: action.payload.message
          },
        },
        emailLoaders: { ...state.emailLoaders, sendEmail: false },
    }

    // case actions.CONVERT_QUOTE:
    //   return {
    //     ...state, 
    //     dashboardLoaders: {...state.dashboardLoaders, convertingQuote: true, },
    // }
    // case actions.CONVERT_QUOTE_SUCCESS:
    //   return {
    //     ...state, 
    //     convertQuote: {
    //         quote_id: null,
    //         state: false,
    //         error: false,
    //         success: true,
    //         new_quote_id: action.payload.quote_id
    //     },
    //     dashboardLoaders: { ...state.dashboardLoaders, convertingQuote: false },
    // }
    // case actions.CONVERT_QUOTE_FAILURE:
    //   return {
    //     ...state, 
    //     convertQuote: {
    //         quote_id: null,
    //         state: false,
    //         error: true,
    //         success: false,
    //         new_quote_id: null,
    //     },
    //     dashboardLoaders: { ...state.dashboardLoaders, convertingQuote: false },
    // }

    // DASH/QUOTES




    case actions.QUOTE_SET_STATE:
        return produce(state, (draft) => {
        draft.quoteStates[action.payload.type] = action.payload.value
    })

    case actions.ORDER_SET_STATE:
        return produce(state, (draft) => {
        draft.orderStates[action.payload.type] = action.payload.value
    })
    case actions.QUOTE_BULK_UPDATE_STATES:
      return produce(state, (draft) => {
        draft.quoteStates = {
          ...draft.quoteStates,
          ...action.payload
        }
    })
    case actions.GET_CUSTOMER_QUOTES:
      return produce(state, (draft) => {
        draft.quotes = [];
        draft.quotesLoaders.getQuotes = true;
    })
    case actions.DASHBOARD_UPDATE_PRODUCT_LIMITATIONS:
      return produce(state, (draft) => {
        if(action.payload.type == 'original'){
          if(draft?.quote?.keys?.order_items){
            if(draft?.quote?.keys?.order_items[action.payload.idx] != 'undefined'){
              draft.quote.keys.order_items[action.payload.idx] = {
                ...draft.quote.keys.order_items[action.payload.idx],
                ...action.payload.data
              };
            }
          }
          // else {
          //   draft.quote.keys.order_items = [];
          // }

        }else {
          if(draft?.updatedQuote?.order_items && draft.updatedQuote.order_items?.[action.payload.idx] != 'undefined'){
            draft.updatedQuote.order_items[action.payload.idx] = {
              ...draft.updatedQuote.order_items[action.payload.idx],
              ...action.payload.data
            };
          }
          else {
            // alert('There was an error! Please refresh the page');
           console.warn("There was an issue", action.payload)
          }
        }
    })
    case actions.QUOTE_RESET_STATES:
      return produce(state, (draft) => {
        draft.quoteStates = initialState.quoteStates;
    })
    case actions.GET_CUSTOMER_QUOTES_SUCCESS:
      return produce(state, (draft) => {
        draft.quotes = action.payload;
        draft.quotesLoaders.getQuotes = false;
    })
    case actions.GET_CUSTOMER_QUOTES_FAILURE:
      return produce(state, (draft) => {
        draft.quotes = action.payload;
        draft.quotesLoaders.getQuotes = false;
    })


    // case actions.DASHBOARD_SET_NEW_QUOTE_SUCCESS:
    //   return {
    //     ...state, 
    //     updatedQuote: {
    //       ...state.updatedQuote,
    //       ...action.payload
    //     },
    //     dashboardLoaders: { ...state.dashboardLoaders, setNewQuote: false },
    // }
    // DASH/ORDERS
    case actions.DASHBOARD_SET_NEW_ORDER:
      return produce(state, (draft) => {
        draft.order = action.payload;
    })
    case actions.DASHBOARD_SET_NEW_QUOTE:
      return produce(state, (draft) => {
        draft.quoteStates.recalculatingQuote = false;
        draft.updatedQuote = {
          ...draft.updatedQuote,
          ...action.payload
        }
    })
    case actions.DASHBOARD_SET_CURR_QUOTE:
      return {
        ...state, 
        quote: {
          ...state.quote,
          ...action.payload
        }
    }
    case actions.DASHBOARD_SET_CURR_ORDER:
      return {
        ...state, 
        order: {
          ...state.order,
          ...action.payload
        }
    }
    case actions.QUOTE_UPDATE_CHARGES:
      return produce(state, (draft) => {
        draft.updatedQuote.charges = action.payload;
    })
    // DASH/ORDERS
    case actions.GET_CUSTOMER_ORDERS:
      return produce(state, (draft) => {
        draft.orders = [];
        draft.ordersLoaders.getOrders = true;
    })
    case actions.GET_CUSTOMER_ORDERS_SUCCESS:
      return produce(state, (draft) => {
        draft.orders = action.payload;
        draft.ordersLoaders.getOrders = false;
    })
    case actions.GET_CUSTOMER_ORDERS_FAILURE:
      return produce(state, (draft) => {
        draft.orders = action.payload;
        draft.ordersLoaders.getOrders = false;
    })
    // DASH/ORDERS/ID
    case actions.GET_SINGLE_ORDER_BY_ID:
      return produce(state, (draft) => {
        draft.order = [];
        draft.ordersLoaders.getOrder = true;
    })
    case actions.GET_SINGLE_ORDER_BY_ID_SUCCESS:
      return produce(state, (draft) => {
        draft.order = action.payload;
        draft.ordersLoaders.getOrder = false;
    })
    case actions.GET_SINGLE_ORDER_BY_ID_FAILURE:
      return produce(state, (draft) => {
        draft.order = action.payload;
        draft.ordersLoaders.getOrder = false;
    })

    // DASH/ADDRESSES
    case actions.GET_ADDRESSES:
      return produce(state, (draft) => {
        draft.addresses = [];
        draft.addressLoaders.getAddresses = true;
    })
    case actions.GET_ADDRESSES_SUCCESS:
      return produce(state, (draft) => {
        draft.addresses = action.payload;
        draft.addressLoaders.getAddresses = false;
    })
    case actions.GET_ADDRESSES_FAILURE:
      return produce(state, (draft) => {
        draft.addresses = action.payload;
        draft.addressLoaders.getAddresses = false;
    })

    case actions.EDIT_ADDRESS:
      return produce(state, (draft) => {
        draft.addressLoaders.editAddress = true;
    })
    case actions.EDIT_ADDRESS_SUCCESS:
      return produce(state, (draft) => {
        draft.addresses.data.address_list[action.payload.index] = action.payload.data.updated_address;
        draft.addressLoaders.editAddress = false;
    })
    case actions.EDIT_ADDRESS_FAILURE:
      return produce(state, (draft) => {
        draft.addressLoaders.editAddress = false;
        draft.addresses.updatingError = action.payload
    })

    case actions.NEW_ADDRESS:
      return produce(state, (draft) => {
        draft.addressLoaders.newAddress = true;
    })
    case actions.NEW_ADDRESS_SUCCESS:
      return produce(state, (draft) => {
        if(draft?.addresses?.data){
          draft.addresses.data.address_list = action.payload.data;
          draft.addresses.updatingError = [];
        }
        draft.addressLoaders.newAddress = false;
    })
    case actions.NEW_ADDRESS_FAILURE:
      return produce(state, (draft) => {
        draft.addressLoaders.newAddress = false;
        draft.addresses.newAddressError = action.payload
    })

    case actions.COPY_ADDRESS:
      return produce(state, (draft) => {
        draft.addressLoaders.copyAddress = true;
    })
    case actions.COPY_ADDRESS_SUCCESS:
      return produce(state, (draft) => {
        draft.addresses.data.address_list = action.payload.data;
        draft.addressLoaders.copyAddress = false;
        draft.addresses.updatingError = [];
    })
    case actions.COPY_ADDRESS_FAILURE:
      return produce(state, (draft) => {
        draft.addressLoaders.copyAddress = false;
        draft.addresses.newAddressError = action.payload
    })
    case actions.SET_SINGLE_CREDIT_DATA:
      return produce(state, (draft) => {
        draft.dashboardData.credit_note = action.payload
    })
    //singles

    
    
    case actions.RESET_ORDER:
      return {
        ...state,
        order: [],
    }
    case actions.QUOTE_RESET_QUOTE_PAYMENT:
      return {
        ...state,
        quote_payment: initialState.quote_payment
    }
    case actions.QUOTE_UPDATE_STATUS_COLOUR:
      return {
        ...state,
        quote: {
          ...state.quote,
          quote_status: {
            colour: action.payload
          }

        }
    }
    case actions.QUOTE_UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        quote_payment: {
          ...state.quote_payment,
          ...action.payload
        }
    }
    case actions.ORDER_UPDATE_PAYMENT_STATUS:
      return {
        ...state,
        order_payment: {
          ...state.quote_payment,
          ...action.payload
        }
    }

    case actions.QUOTE_SET_SUCCESSFUL_QUOTE_UPDATE:
      return produce(state, (draft) => {
        draft.successfulQuoteUpdate = action.payload;
    })
    case actions.UPDATE_ADDRESSES:
      return produce(state, (draft) => {
        draft.addresses = action.payload;
    })

    case actions.TOGGLE_CHECKLIST_SAME_AS_DELIVERY:
      return produce(state, (draft) => {
        draft.checkList.sameAsDelivery = action.payload;
    })

    case actions.SET_CHECKLIST_COMPLETED:
      return produce(state, (draft) => {
        draft.checkList[`${action.payload.type}ChecklistCompleted`] = action.payload.completed;
    })

    case actions.HANDLE_NAVIGATE_AWAY_QUOTE_STATE:
      return produce(state, (draft) => {
        draft.quote = [];
        draft.updatedQuote = [];
        draft.quote_payment = [];
    })


    case actions.QUOTE_ORDER_RESET_SENT_EMAIL:
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          sendEmail: [],
        },
    }

    case actions.DASHBOARD_RESET_ACTIVE_QUOTE_STATE:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          quote: []
        },
        dashboardLoaders: { ...state.dashboardLoaders},
    }
    case actions.DASHBOARD_RESET_ACTIVE_ORDER_STATE:
      return {
        ...state, 
        dashboardData: {
          ...state.dashboardData,
          order: []
        },
        dashboardLoaders: { ...state.dashboardLoaders},
    }
    case actions.DASHBOARD_SET_NEW_ORDER_ITEMS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          keys: {
            ...state.quote.keys,
            order_items: action.payload
          }
        },
    }
    case actions.DASHBOARD_RESET_UPDATED_QUOTE:
      return {
        ...state, 
        updatedQuote: [],
    }
    case actions.DASHBOARD_SET_LIMITATIONS:
      return {
        ...state, 
        quote: {
          ...state.quote,
          keys: {
            ...state.quote['keys'],
            limitationsData: action.payload 
          }
        },
    }
    case actions.TOGGLE_MOBILE_SIDE_NAV:
      return {
        ...state, 
        mobileDrawer: {
          open: action.payload
        }
    }
    case actions.RESET_QUOTE_STATE:
      return {
        ...state, 
        quote: [],
        updatedQuote: []
    }

    case actions.ORDER_GET_EMAIL_LOG:
      return {
        ...state, 
        ordersLoaders: {...state.ordersLoaders, fetchingEmailLogs: true, },
    }
    case actions.ORDER_GET_EMAIL_LOG_SUCCESS:
      return {
        ...state, 
        order: {
          ...state.order,
          data: {
            ...state.order.data,
            email_logs: action.payload.data
          }
        },
        ordersLoaders: {...state.ordersLoaders, fetchingEmailLogs: false, },
    }
    case actions.ORDER_GET_EMAIL_LOG_FAILURE:
      return {
      ...state, 
      order: {
        ...state.order,
        data: {
          ...state.quote.data,
          email_logs: action.payload.data
        }
      },
      ordersLoaders: {...state.ordersLoaders, fetchingEmailLogs: false, },
    }


    // SCHEDULE POSTCODE

    case actions.SCHEDULE_GET_BY_POSTCODE_DELIVERIES:
      return {
        ...state, 
        schedulePostcodeLoaders: {...state.schedulePostcodeLoaders, getDeliveries: true, },
    }
    case actions.SCHEDULE_GET_BY_POSTCODE_DELIVERIES_SUCCESS:
      return {
        ...state, 
        schedule: {
          ...state.schedule,
          deliveries: {
            ...state.schedule.deliveries,
            ...action.payload
          }
        },
        schedulePostcodeLoaders: {...state.schedulePostcodeLoaders, getDeliveries: false, },
    }
    case actions.SCHEDULE_GET_BY_POSTCODE_DELIVERIES_FAILURE:
      return {
      ...state, 
      schedule: {
        ...state.schedule,
        deliveries: {
          ...state.schedule.deliveries,
          ...action.payload
        }
      },
      schedulePostcodeLoaders: {...state.schedulePostcodeLoaders, getDeliveries: false, },
    }

    case actions.SCHEDULE_GET_BY_POSTCODE_COLLECTIONS:
      return {
        ...state, 
        schedulePostcodeLoaders: {...state.schedulePostcodeLoaders, getCollections: true, },
    }
    case actions.SCHEDULE_GET_BY_POSTCODE_COLLECTIONS_SUCCESS:
      return {
        ...state, 
        schedule: {
          ...state.schedule,
          collections: {
            ...state.schedule.collections,
            ...action.payload
          }
        },
        schedulePostcodeLoaders: {...state.schedulePostcodeLoaders, getCollections: false, },
    }
    case actions.SCHEDULE_GET_BY_POSTCODE_COLLECTIONS_FAILURE:
      return {
      ...state, 
      schedule: {
        ...state.schedule,
        collections: {
          ...state.schedule.collections,
          ...action.payload
        }
      },
      schedulePostcodeLoaders: {...state.schedulePostcodeLoaders, getCollections: false, },
    }

    case actions.GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES:
      return {
        ...state, 
        preferences: {
          ...state.preferences,
          ['quote']: [],
        },
        preferencesLoaders: {...state.preferencesLoaders, getQuoteTemplates: true, },
    }
    case actions.GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state, 
        preferences: {
          ...state.preferences,
          ['quote']: action.payload,
        },
        preferencesLoaders: {...state.preferencesLoaders, getQuoteTemplates: false, },
    }
    case actions.GET_AGENT_PREFERENCES_QUOTE_EMAIL_TEMPLATES_FAILURE:
      return {
      ...state, 
      preferences: {
        ...state.preferences,
        ['quote']: action.payload,
      },
      preferencesLoaders: {...state.preferencesLoaders, getQuoteTemplates: false, },
    }
    case actions.GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES:
      return {
        ...state, 
        preferences: {
          ...state.preferences,
          ['order']: [],
        },
        preferencesLoaders: {...state.preferencesLoaders, getOrderTemplates: true, },
    }
    case actions.GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state, 
        preferences: {
          ...state.preferences,
          ['order']: action.payload,
        },
        preferencesLoaders: {...state.preferencesLoaders, getOrderTemplates: false, },
    }
    case actions.GET_AGENT_PREFERENCES_ORDER_EMAIL_TEMPLATES_FAILURE:
      return {
      ...state, 
      preferences: {
        ...state.preferences,
        ['order']: action.payload,
      },
      preferencesLoaders: {...state.preferencesLoaders, getOrderTemplates: false, },
    }
    


    // case actions.SET_CONVERT_QUOTE_STATE:
    //   return {
    //     ...state, 
    //     convertQuote: {
    //       ...state.convertQuote,
    //       ...action.payload
    //     },
    //     dashboardLoaders: { ...state.dashboardLoaders},
    // }
    default:
      return state
  }
}

export default Reducer;
