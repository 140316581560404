import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { count, isDefined } from "../config/helpers";
import CacheFactory from "../Factories/CacheFactory";
import cartActions from "../Redux/Cart/actions";
import authActions from "../Redux/Auth/actions";



const useAuth = () => {
      const dispatch = useDispatch();
      const user = useSelector(state => state.authReducer.user);
      const system_preferences = useSelector(state => state.dashboardReducer?.system_preferences);
      const preferencesReady = user?.preferences?.preferencesReady || false;
      const systemPreferencesReady = system_preferences?.system_preferences_ready || false;
      const isAuthenticated = useSelector(state => state.authReducer.isAuthenticated);
      const linkedAccounts = useSelector(state => state.authReducer.linkedAccounts);
      const agentReducer = useSelector((state) => state.agentReducer);
      const [selectedAccount, setSelectedAccount] = useState(null)

      const handleLogout = () => {
          dispatch({ type: authActions.AUTH_LOGOUT });
      }

      useEffect(() => {
        linkedAccounts && isDefined(linkedAccounts.all) && count(linkedAccounts.all) > 0 && isDefined(linkedAccounts.default) && count(Object.keys(linkedAccounts.default)) && (setSelectedAccount(linkedAccounts.default.CustomerID))
      }, [linkedAccounts])
      

      const hasDefaultAccount = () => {
          if(isDefined(user) && (!user?.has_default || user.has_default == 0)){
            return false;
          }
          return true;
      }

      const openAccountModal = () => {
        dispatch({
            type: "CREATE_ACCOUNT_MODAL_TOGGLE",
            payload: true
        })
      }

      const resendVerificationLink = () => {
        dispatch({ type: authActions.AUTH_SEND_EMAIL_VERIFICATION_LINK, payload: user.email });
      }

      const handleRadioChange = (event) => {
          setSelectedAccount(event.target.value)
      }

      const submitDefaultAccount = () => {
          dispatch({ type: authActions.AUTH_SET_DEFAULT_ACCOUNT, payload: selectedAccount })
      }

      const getRoute = (type, id, mode) => {
        let baseRoute = isAgent() ? process.env.NEXT_PUBLIC_USER_CRM_ROUTE : process.env.NEXT_PUBLIC_USER_HOME_ROUTE;

        switch (type) {
          case 'order':
            if(isAgent()){
              return `${baseRoute}/order/${id}`;
            }
            else {
              return `${baseRoute}/orders/${id}`;
            }
          case 'quote':
              return `${baseRoute}/quote/${id}`;
          case 'quoteEditMode':
              return `${baseRoute}/quote/${id}?quickEdit=${mode}`;
          case 'credit': 
            return `${baseRoute}/credits/${id}`;
          case 'customer': 
            if(isAgent()){
              return `${baseRoute}/customer/${id}`
            }
            else {
              return `${baseRoute}`
            }
          case 'addresses':
              if(isAgent()){
                return `${baseRoute}/customer/${id}/addresses`
              }
              else {
                return `${baseRoute}/addresses`
              }
        }
      }

      const isUser = () => {
        if(user.role == 'Private' || user.role == 'Business') return true;

        return false;
      }

      const isAgent = () => {
        if(agentReducer?.user_state == 'Agent' || agentReducer?.user_state == 'SuperUser'){
          return true;
        } 
        else if(agentReducer?.user_state == 'User'){
          return false
        }
        else {
          return user.role == 'Agent' ? true : false;
        }
      }

      const superUser = () => {
        if(agentReducer?.user_state == 'SuperUser'){
          return true;
        }
        else if(agentReducer?.user_state == 'User' || agentReducer?.user_state == 'Agent' ){
          return false
        }
        else {
          return ['2', '3', /* 4 */].reduce(id => id == user.id);
        }
      }

      const getUserName = () => {
        return user?.name ? user.name : '';
      }

      const getUser = () => {
        return user;
      }

      const getDefaultAccount = () => {
        return linkedAccounts?.default || false;
      }
      

      
      return {
        getRoute,
        selectedAccount,
        getUserName,
        setSelectedAccount,
        handleRadioChange,
        submitDefaultAccount,
        hasDefaultAccount,
        openAccountModal,
        resendVerificationLink,
        isUser,
        isAgent,
        superUser,
        handleLogout,
        isAuthenticated,
        getUser,
        getDefaultAccount,
        preferencesReady,
        systemPreferencesReady
      }
}

export default useAuth;