
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getBrand, isDefined } from "../../config/helpers";
import useWindowSize from "../../_hooks/useWindowSize";
import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import useGlobalSettings from "@components/_hooks/useGlobalSettings";
import useVAT from "@components/_hooks/useVAT";

const FooterMobile = dynamic(() => import("./FooterMobile"), {
    ssr: true,
});

const SwitchDetailedLabel = dynamic(() => import("../Reusables/Components/SwitchDetailedLabel"), {
    ssr: true,
});

const FooterDesktop = dynamic(() => import("./FooterDesktop"), {
    ssr: true,
});


const FooterContactForm = dynamic(() => import("./FooterContactForm"), {
    ssr: true
});

const date = new Date().getFullYear();


const Footer = () => {
    const {isMobile, width} = useWindowSize();
    const {open} = useSelector((state) => state.snackbarReducer);
    const VATFlag = useSelector(state => state.globalReducer.VATFlag);
    const global_settings = useSelector(state => state.globalReducer.global_settings);
    const template_settings = useSelector(state => state.globalReducer.template_settings);
    const agent = useSelector(state => state.globalReducer.agent);
    const {getSetting} = useGlobalSettings();
    const {switchVat} = useVAT();
    const [value, setValue] = useState(VATFlag);

    useEffect(() => {
        setValue(VATFlag)
    }, [VATFlag])

    const mobilePage = (width < 1200) || (!isDefined(width) && isDefined(isMobile) && isMobile) ? true : false
    
    const payment_types = [
        "visa",
        "masterCard",
        "paypal",
        "american-express",
        "klarna",
    ];
    const layout = template_settings.layout ? template_settings.layout : "wide";
    const layout_width =
        layout == "custom" ? template_settings.custom_layout_width : "0px";
        
    const handleChange = (e, checked) => {
        setValue(checked);
        switchVat(checked)
    }

    const hideToggle = () => {
		if(getSetting('vat_toggle_visible', 'yes') == 'yes'){
			return (
				<SwitchDetailedLabel 
                    value={value}
                    onChange={handleChange}
                    labels={['', '']}
                 />
			)
		}
	}

    return (
        (<footer className={`${getBrand('EH') ? 'background-grey pt-6 pb-0 mt-0' : ''}`}>
            <FooterContactForm isMobile={mobilePage} />
            {/* {open && <SnackBar />} */}
            {(mobilePage) ? ( //mobile
                (<>
                    <FooterMobile/>
                </>)
                
            ) : ( //desktop
                (<FooterDesktop />)
            )}
            <Box className="text-center d-flex p-4 mt-2 background-grey" justifyContent="center" alignItems="center">
                <Typography>&copy; {date} {getSetting('office_name', 'Central Event Hire')} Ltd. All Rights Reserved. All prices are {VATFlag ? 'inc' : 'ex'} VAT.</Typography>
                {hideToggle()}
            </Box>
        </footer>)
    );
};

export default React.memo(Footer)
