import { Poppins } from 'next/font/google';
import { Provider } from "react-redux";
// import { AuthGuard } from "../services/Auth/AuthGuard"; //adds on 40kb to build bundle size
import { useRouter } from "next/router";
import { LicenseInfo } from "@mui/x-license-pro";
import { wrapper } from "../js/Redux/store";
import dynamic from "next/dynamic";

import "../styles/globals.css";
import "../sass/app.scss";
import "../sass/global_classes.scss";
import "../sass/_font.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'photoswipe/dist/photoswipe.css'

import { useMemo, useRef } from "react";
import Head from "next/head";
import Bugsnag from '@bugsnag/js'

const Error = dynamic(() => import("./_error"));


const poppins = Poppins({  weight: ['300','400', '700'], subsets: ['latin'] ,  style: ['normal'],}); 

if (!Bugsnag._client && process.env.NEXT_PUBLIC_APP_ENV == 'production') {
    Bugsnag.start({
        apiKey: process.env.NEXT_PUBLIC_BUGSNAG_KEY,
        appType: "client"
    })
  }

LicenseInfo.setLicenseKey(
    process.env.NEXT_PUBLIC_MUI_KEY
);


const Layout = dynamic(() => import("../js/Custom/Layout"));


const ErrorBoundary = dynamic(() => import("./500"), {
    //lazy load to assist in performance
    ssr: true,
});

function MyApp({ Component, pageProps, globalProps, pathData, utm_data, ...rest }) {
    const { store } = wrapper.useWrappedStore(rest);
    const router = useRouter();
    const propsRef = useRef({ globalProps });

    // Prefetching logic if necessary - review this part for correctness
    useMemo(() => {
        router.prefetch = async () => { }
    }, [router]);
    // useEffect(() => {
    //     const originalPrefetch = router.prefetch.bind(router);
    
    //     // Debounced version of the prefetch function
    //     const debouncedPrefetch = debounce(async (url) => {
    //       if (!isPrefetching.current && !prefetchedRoutes.current.has(url)) {
    //         isPrefetching.current = true;
    //         try {
    //           await originalPrefetch(url);
    //           prefetchedRoutes.current.add(url); // Add url to the set of prefetched routes
    //         } finally {
    //           isPrefetching.current = false;
    //         }
    //       }
    //     }, 200);
    //     // Return function to reset the prefetch method when the component unmounts
    //     return () => {
    //       router.prefetch = originalPrefetch;
    //     };
    //   }, [router]);


    const initializeGlobalState = () => {
        if (store.getState().globalReducer.firstRender) {
            store.dispatch({
                type: 'GLOBAL_INIT_VARIABLES',
                payload: globalProps
            });

            // if (globalProps.template_settings.portal_status === 'enabled' && globalProps.user) {
            //     store.dispatch({
            //         type: 'AUTH_SET_USER',
            //         payload: {
            //             user: globalProps.user,
            //             linkedAccounts: globalProps.linkedAccounts,
            //             defaultAccount: globalProps.defaultAccount,
            //             ...globalProps.loginData,
            //             loadUser: !(router.query.expires && router.query.expires)
            //         }
            //     });
            // }
            
            const breadcrumbs = pageProps?.data?.breadcrumb;
            store.dispatch({
                type: "SET_GLOBAL_DATA",
                payload: {
                    // VATFlag: default to true/false based on your setting
                    breadcrumbs,
                    sidenav: pageProps?.data?.template_page_settings?.sidenav === 'yes' && globalProps?.template_settings?.sidenav === 'yes',
                    VATFlag: globalProps?.template_settings?.default_vat_toggle === 'inc_vat'
                }
            })
        }
    };

    const renderHead = () => {
        const meta = store.getState().globalReducer.template_page_content.home.meta;
        return meta ? (
            <Head>
                    <title>{store.getState().globalReducer.template_page_content.home.meta.meta_title}</title>
                    <meta
                        name="description"
                        content={store.getState().globalReducer.template_page_content.home.meta.meta_description}
                    />
                    <meta name="msapplication-TileColor" content="#da532c"/>
                    <meta name="theme-color" content="#ffffff"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0,maximum-scale=1.0,user-scalable=no"/>
                    <meta charSet="utf-8"/>
            </Head>
        ) : null;
    };

    initializeGlobalState();

    if (pageProps?.error || globalProps?.error) {
        return <Error statusCode={pageProps?.statusCode || globalProps.statusCode} />;
    }

    return (
        <Provider store={store}>
            {renderHead()}
            <main className={poppins.className}>
                <Layout 
                    {...pageProps}
                     _cookie={pathData?._cookie || false} 
                     utm_data={utm_data} 
                     breadcrumb={pageProps?.data?.breadcrumb}
                     pagePropsData={pageProps?.data}
                >
                    <ErrorBoundary FallbackComponent={Error}>
                        <Component {...pageProps} />
                    </ErrorBoundary>
                </Layout>
            </main>
        </Provider>
    );
}


const parseUserAgent = (userAgent) => {
    if(typeof userAgent == 'undefined'){
        return {
            user_agent: null,
            isLine: null,
            isMobile: null,
            isInApp: null,
        }
    }
    
    const rules = [
        'WebView',
        '(iPhone|iPod|iPad)(?!.*Safari/)',
        'Android.*(wv|.0.0.0)'
    ];
    const regex = new RegExp(`(${rules.join('|')})`, 'ig')
 

    return {
        user_agent: userAgent,
        isLine: /\bLine\//i.test(userAgent),
        isMobile: /(iPhone|Android|Mobile)/i.test(userAgent),
        isInApp: Boolean(userAgent.match(regex)),
    };
}

const getUtmData = (query) => {
    return {
        utm_source: query.utm_source || null,
        utm_medium: query.utm_medium || null,
        utm_campaign: query.utm_campaign || null,
        came_from: query.camefrom || null,
        // gclid: query.gclid || null,
    };
}

const getBasePath = (asPath) => {
    const path = asPath.match(new RegExp("/", "g")) || [];
    let basePath = asPath;
    if (path.length === 2) {
        basePath = basePath.substring(1);
        basePath = basePath.slice(0, basePath.indexOf("/"));
    }
    return basePath;
}

/**
 * Fetch some data server side before rendering the page client side.
 *
 * @param {object} context
 *   The context object.
 */
 MyApp.getInitialProps = async (appContext) => {
    const ctx = appContext.ctx;
    const {req, res, query, asPath} = ctx;
    const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;
    const isServer = typeof window === 'undefined';

    const agent = parseUserAgent(userAgent);
    const utm_data = getUtmData(query);
    const basePath = getBasePath(asPath);
    const _cookie = req?.cookies?.['XSRF-TOKEN'] || false;

    if (isServer) {
        try {
            const dataReq = await fetch(`${process.env.NEXT_PUBLIC_BASE_API_URL}get-global-data`);
            const data = await dataReq.json();

            // const authenticator = new AuthGuard();
            // const user = await authenticator.authenticateUser(req, res, ctx.pathname, 'disabled', data.template_settings.portal_maintenance);
            const user = false;
            const propsToReturn = {
                globalProps: {
                        ...user,
                        ...data,
                        brand: process.env.brand,
                        agent: agent,
                },
                pathData: {
                    basePath: basePath,
                    pathname: ctx.pathname,
                    _cookie: _cookie
                },
                utm_data
            }

            return {...propsToReturn};
          
        } catch (error) {
            console.error(error);
            Bugsnag.notify(error)
            return {
                globalProps: {
                    error: true,
                    statusCode: 500
                }
            };
        }
    } else {
        // Client-side navigation
        return {
            pathData: { basePath, pathname: ctx.pathname, _cookie },
        };
    }
};

export default MyApp;
