import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

const SwitchDetailedLabel = (props) => {
    const {
        disabled = false,
        value = false,
        labels = ['Off', 'On'],
        onChange = () => null
    } = props;

    return (
        <Stack className="pr-2" direction="row" alignItems="center">
            <Typography className="fw-bold" color="secondary" variant="subtitle1" sx={{fontSize: {xs: 7, md: 10}}}>{labels[0]}</Typography>
            <Switch    
                disabled={disabled}
                sx={{
                    '& .MuiButtonBase-root': {
                        color: value ? 'secondaryButton.main' : 'error.main',
                    },
                }}
                color="secondaryButton"
                checked={value}
                onChange={onChange}
            />
            <Typography className="fw-bold" color={value ? "secondary" : "error"} variant="subtitle1" sx={{fontSize: {xs: 7, md: 10}}}>{labels[1]}</Typography>
        </Stack>
    )

}

export default SwitchDetailedLabel